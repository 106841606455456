import React, { useContext, useState } from 'react';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import firebase from '../../../FirebaseConfig';

import { RootContext } from '../../../app/contexts/RootContextProvider';

import DefaultLayout from '../../layouts/DefaultLayout';
import Seo from '../../../app/Seo';
import Page from '../../components/Page';

import SignInWithFacebook from './SignInWithFacebook';
import SignInWithGoogle from './SignInWithGoogle';

// import SignInWithProvider from './SignInWithProvider';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import GoogleIcon from '@mui/icons-material/Google';

const auth = firebase.auth();

const googleProvider = new GoogleAuthProvider();
googleProvider.setCustomParameters({
  display: 'popup',
});

const facebookProvider = new FacebookAuthProvider();
facebookProvider.setCustomParameters({
  display: 'popup',
});

const getProviderForProviderId = (method) => {
  // console.log(`method`, method);
  if (method.includes(`google`)) {
    return googleProvider;
  } else if (method.includes(`facebook`)) {
    return facebookProvider;
  } else {
    alert(`No Provider found for method: ${method}`);
    return null;
  }
};

export default function AccountSignInPage() {
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = React.useState(null);
  const [pendingCredential, setPendingCredential] = useState(null);
  const [previousProviderForSignIn, setPreviousProviderForSignIn] =
    useState(null);
  const [newProviderForSignIn, setNewProviderForSignIn] = useState(null);
  const { currentUser } = useContext(RootContext);

  // console.log(`auth`, auth);
  // console.log(`googleProvider`, googleProvider);
  // console.log(`facebookProvider`, facebookProvider);
  // console.log(`currentUser from useContext`, currentUser);

  // const auth = firebase.auth();
  // const auth = getAuth();
  // console.log(`auth.currentUser`, auth.currentUser);

  const handleOnCloseDialog = () => {
    setOpen(false);
  };

  const handleOnSignInForNewProvider = () => {
    auth.signInWithPopup(previousProviderForSignIn).then(function (result) {
      // Remember that the user may have signed in with an account that has a different email
      // address than the first one. This can happen as Firebase doesn't control the provider's
      // sign in flow and the user is free to login using whichever account they own.
      // Step 4b.
      // Link to Google credential.
      // As we have access to the pending credential, we can directly call the link method.
      setOpen(false);

      result.user
        .linkAndRetrieveDataWithCredential(pendingCredential)
        .then(function (usercred) {
          // Google account successfully linked to the existing Firebase user.
          console.log(
            `New provider account successfully linked to the existing Firebase user.`
          );
          // TODO
          // goToApp();
        });
    });
  };

  const handleLogin = (provider) => {
    setNewProviderForSignIn(provider);
    // console.log(`handleLogin provider:`, provider);

    // Step 1.
    // User tries to sign in to Google.
    auth
      .signInWithPopup(provider)
      .then((result) => {})
      .catch(function (error) {
        // console.log(`error`, error);
        // An error happened.
        if (error.code === 'auth/account-exists-with-different-credential') {
          // Step 2.
          // User's email already exists.
          // The pending Google credential.
          var pendingCred = error.credential;
          setPendingCredential(error.credential);
          // console.log(`setPendingCredential(error.credential)`, error.credential);
          // The provider account's email address.
          var emailFromError = error.email;
          setEmail(emailFromError);
          // console.log(`email`, email);
          // Get sign-in methods for this email.
          auth
            .fetchSignInMethodsForEmail(emailFromError)
            .then(function (methods) {
              // console.log(`methods`, methods);
              // Step 3.
              // If the user has several sign-in methods,
              // the first method in the list will be the "recommended" method to use.
              if (methods[0] === 'password') {
                // Asks the user their password.
                // In real scenario, you should handle this asynchronously.
                // TODO: implement promptUserForPassword.
                var password = `some password`; // promptUserForPassword();
                auth
                  .signInWithEmailAndPassword(emailFromError, password)
                  .then(function (result) {
                    // Step 4a.
                    return result.user.linkWithCredential(pendingCred);
                  })
                  .then(function () {
                    // Google account successfully linked to the existing Firebase user.
                    console.log(
                      `New provider account successfully linked to the existing Firebase user.`
                    );
                    // TODO
                    // goToApp();
                  });
                return;
              }
              // All the other cases are external providers.
              // Construct provider object for that provider.
              // TODO: implement getProviderForProviderId.
              var previousProvider = getProviderForProviderId(methods[0]);
              setPreviousProviderForSignIn(previousProvider);
              setOpen(true);

              // At this point, you should let the user know that they already have an account
              // but with a different provider, and let them validate the fact they want to
              // sign in with this provider.
              // Sign in to provider. Note: browsers usually block popup triggered asynchronously,
              // so in real scenario you should ask the user to click on a "continue" button
              // that will trigger the signInWithPopup.
              // auth.signInWithPopup(previousProvider).then(function (result) {
              //   // Remember that the user may have signed in with an account that has a different email
              //   // address than the first one. This can happen as Firebase doesn't control the provider's
              //   // sign in flow and the user is free to login using whichever account they own.
              //   // Step 4b.
              //   // Link to Google credential.
              //   // As we have access to the pending credential, we can directly call the link method.
              //   result.user
              //     .linkAndRetrieveDataWithCredential(pendingCred)
              //     .then(function (usercred) {
              //       // Google account successfully linked to the existing Firebase user.
              //       console.log(
              //         `New provider account successfully linked to the existing Firebase user.`
              //       );
              //       // TODO
              //       // goToApp();
              //     });
              // });
            });
        }
      });
  };

  // Listen to the Firebase Auth state and set the local state.
  // useEffect(() => {
  //   const unregisterAuthObserver = firebase
  //     .auth()
  //     .onAuthStateChanged((user) => {
  //       setReadyToRender(true);
  //     });
  //   return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  // }, []);

  return (
    <>
      <Seo title='Account | Sign In' />
      <DefaultLayout>
        <Page pageTitle='Sign In to Magic Audio'>
          {typeof currentUser !== `undefined` && currentUser && (
            <Typography variant='h4' align='center'>
              You are now Signed In!
            </Typography>
          )}
          {typeof currentUser !== `undefined` && !currentUser && (
            <Stack
              spacing={1}
              direction='column'
              justifyContent='center'
              alignItems='center'
              divider={<Divider orientation='vertical' flexItem />}
            >
              <SignInWithGoogle
                handleLogin={() => handleLogin(googleProvider)}
              />
              <SignInWithFacebook
                handleLogin={() => handleLogin(facebookProvider)}
              />
            </Stack>
          )}

          <Dialog
            open={open}
            onClose={handleOnCloseDialog}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {`Continue with new provider for ${email}?`}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                There is already an account for '{email}' associated with{' '}
                {previousProviderForSignIn
                  ? previousProviderForSignIn.providerId
                  : ``}
                . To link you account with{' '}
                {newProviderForSignIn
                  ? newProviderForSignIn.providerId
                  : `new provider`}{' '}
                you must authenticate again with{' '}
                {previousProviderForSignIn
                  ? previousProviderForSignIn.providerId
                  : ``}
                . Would you like to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button color='primary' onClick={handleOnCloseDialog}>
                Cancel
              </Button>
              <Button
                color='primary'
                variant='contained'
                onClick={handleOnSignInForNewProvider}
              >
                Continue
              </Button>
            </DialogActions>
          </Dialog>
        </Page>
      </DefaultLayout>
    </>
  );
}
