import React from 'react';
import Button from '@mui/material/Button';
import FacebookIcon from '@mui/icons-material/Facebook';

interface SignInWithFacebookProps {
  handleLogin: any;
}

const SignInWithFacebook = ({ handleLogin }: SignInWithFacebookProps) => {
  return (
    <Button
      size='large'
      variant='contained'
      color='primary'
      onClick={handleLogin}
      startIcon={<FacebookIcon />}
      sx={{ minWidth: '250px' }}
    >
      Sign In with Facebook
    </Button>
  );
};

export default SignInWithFacebook;
