import React from 'react';
import Button from '@mui/material/Button';
import GoogleIcon from '@mui/icons-material/Google';
// import Icons from '../../../../icons';

interface SignInWithGoogleProps {
  handleLogin: any;
}

const SignInWithGoogle = ({ handleLogin }: SignInWithGoogleProps) => {
  return (
    <Button
      size='large'
      variant='contained'
      color='primary'
      onClick={handleLogin}
      startIcon={<GoogleIcon />}
      sx={{ minWidth: '250px' }}
    >
      Sign In with Google
    </Button>
  );
};

export default SignInWithGoogle;
